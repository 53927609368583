import parseISO from 'date-fns/parseISO';
import orderBy from 'lodash/orderBy';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { globalState, updateState } from '../../../shared/foreground/models';
import background from '../../../shared/foreground/portalGates/toBackground/singleProcess';
import {
  deleteFeedSuggestions,
  restoreDeletedFeedSuggestions,
  subscribeToSuggestedFeeds,
} from '../../../shared/foreground/stateUpdaters/persistentStateUpdaters/feed';
import useGlobalStateWithFallback from '../../../shared/foreground/utils/useGlobalStateWithFallback';
import useLiveValueRef from '../../../shared/foreground/utils/useLiveValueRef';
import { RSSSuggestion, SortOrder, TableHeader, TableSortKey } from '../../../shared/types';
import { ShortcutId } from '../../../shared/types/keyboardShortcuts';
import getFormattedDurationFromNow from '../../../shared/utils/dates/getFormattedDurationFromNow';
import makeLogger from '../../../shared/utils/makeLogger';
import { useKeyboardShortcutPreventDefault } from '../hooks/useKeyboardShortcut';
import { useShortcutsMap } from '../utils/shortcuts';
import Button from './Button';
import Checkbox from './Checkbox';
import { Dialog } from './Dialog';
import { Dropdown, DropdownOptionType } from './Dropdown/Dropdown';
import styles from './FeedsSourceList.module.css';
import DeleteIcon from './icons/DeleteIcon';
import FeedFallbackFavicon from './icons/FeedFallbackFavicon';
import FeedIconHeader from './icons/FeedIconHeader';
import RadarIcon from './icons/RadarIcon';
import { ImageWithFallback } from './ImageWithFallback';
import Loader from './Loader';
import { Table } from './Table';
import Tooltip from './Tooltip';

const logger = makeLogger(__filename);

const FeedSuggestionItem = React.memo(function _FeedSuggestionItem({
  id,
  feed,
  isChecked,
  onCheck,
}: {
  id: string;
  feed: RSSSuggestion;
  isChecked: boolean;
  onCheck: (feedId: string, selected: boolean) => void;
}) {
  const itemRef = useRef<HTMLTableRowElement>(null);
  const name = feed.name;
  const description = feed.description;
  const imageUrl = feed.image_url;
  const docsCount = feed.documents ?? 0;
  const lastUpdated = feed.lastPosted || '';
  const frequency = feed.frequency ?? 0;

  const feedSelectedHandler = useCallback(
    (checked: boolean) => {
      onCheck(id, checked);
    },
    [id, onCheck],
  );

  return (
    <tr ref={itemRef} className={`${styles.feedItem} ${isChecked ? styles.isSelected : ''}`}>
      <td className={styles.tdWithCheckbox}>
        <Checkbox
          isChecked={isChecked}
          onCheckedChange={feedSelectedHandler}
          labelId={`check-${feed.id}`}
        />
      </td>
      <Tooltip content={feed.url} maxWidth="unset">
        <td className={styles.name}>
          <Link to={`/filter/domain:"${feed.domain}"`} className={styles.nameWithImage}>
            <ImageWithFallback
              imageUrl={imageUrl}
              loading="lazy"
              width="24px"
              height="24px"
              fallbackImage={<FeedFallbackFavicon height="24" width="24" />}
            />
            <span>{name ?? `No name (${feed.url})`}</span>
          </Link>
        </td>
      </Tooltip>
      <td className={styles.description}>
        <Link to={`/filter/domain:"${feed.domain}"`}>
          <span>{description}</span>
        </Link>
      </td>
      <td className={styles.lastUpdated}>{`${frequency < 1 ? 'less than 1' : frequency} per week`}</td>
      <td className={styles.documents}>{docsCount ?? 0}</td>
      <td className={styles.lastUpdated}>
        {lastUpdated ? getFormattedDurationFromNow(parseISO(lastUpdated)) : '-'}
      </td>
    </tr>
  );
});

const TableEmptyState = ({
  icon,
  text,
  className = '',
}: { icon: JSX.Element; text: string; className?: string }) => {
  return (
    <div className={`${styles.emptyStateContainer} ${className}`}>
      <div className={styles.radarWrapper}>
        {icon}
        <p>{text}</p>
      </div>
    </div>
  );
};

const FeedSuggestionsTable = React.memo(function _FeedSuggestionsTable({
  id,
  feedsSuggestions,
  setSelected,
  selectedFeeds,
  emptyTable,
}: {
  id: string;
  feedsSuggestions: RSSSuggestion[];
  setSelected: (selected: string[] | ((previous: string[]) => string[])) => void;
  selectedFeeds: string[];
  emptyTable: JSX.Element;
}) {
  const [sortByKey, setSortByKey] = useState(TableSortKey.Documents);
  const [sortOrder, setSortOrder] = useState(SortOrder.Desc);

  const orderedSuggestions = useMemo(() => {
    return orderBy(
      feedsSuggestions,
      [
        (feed) => {
          switch (sortByKey) {
            case TableSortKey.Name:
              return feed.name?.toLocaleLowerCase() || '';
            case TableSortKey.Description:
              return feed.description?.toLocaleLowerCase() || '';
            case TableSortKey.Frequency:
              return feed.frequency ?? 0;
            case TableSortKey.Documents:
              return feed.documents ?? 0;
            case TableSortKey.LastUpdated: {
              const lastUpdated = feed.lastPosted;

              if (!lastUpdated && sortOrder === SortOrder.Asc) {
                return Infinity;
              } else {
                return lastUpdated ?? 0;
              }
            }
          }
        },
      ],
      [sortOrder],
    );
  }, [feedsSuggestions, sortByKey, sortOrder]);

  const selectAllValue = useMemo(() => {
    if (!feedsSuggestions.length) {
      return false;
    }
    if (feedsSuggestions.length === selectedFeeds.length) {
      return true;
    } else if (selectedFeeds.length) {
      return 'indeterminate';
    } else {
      return false;
    }
  }, [selectedFeeds, feedsSuggestions]);

  const toggleAllFeeds = useCallback(
    (checked: boolean) => {
      if (checked) {
        setSelected(feedsSuggestions.map((f) => f.id));
      } else {
        setSelected([]);
      }
    },
    [feedsSuggestions, setSelected],
  );

  const tableHeaders = useMemo(
    () =>
      [
        {
          title: (
            <Checkbox
              isChecked={selectAllValue}
              onCheckedChange={toggleAllFeeds}
              labelId={`allFeedTable-${id}`}
            />
          ),
          width: '32px',
          sortkey: TableSortKey.SelectAll,
        },
        {
          title: 'Feed Name',
          width: '22%',
          // minWidth: '22%',
          sortkey: TableSortKey.Name,
        },
        {
          title: 'Description',
          // width: '35%',
          minWidth: '35%',
          sortkey: TableSortKey.Description,
        },
        {
          title: 'Frequency',
          width: '15%',
          sortkey: TableSortKey.Frequency,
        },
        {
          title: 'Documents',
          width: '8%',
          // minWidth: '8%',
          sortkey: TableSortKey.Documents,
        },
        {
          title: 'Last Posted',
          width: '15%',
          // minWidth: '15%',
          sortkey: TableSortKey.LastUpdated,
        },
      ] as TableHeader[],
    [id, selectAllValue, toggleAllFeeds],
  );

  const onHeaderClick = (key: TableSortKey) => {
    if (key === TableSortKey.SelectAll) {
      return;
    }
    if (key === sortByKey) {
      setSortOrder(sortOrder === SortOrder.Asc ? SortOrder.Desc : SortOrder.Asc);
      return;
    }
    setSortByKey(key);
  };

  const handleFeedSelection = useCallback(
    (feedId: string, selected: boolean) => {
      if (selected) {
        setSelected((previous) => [...previous, feedId]);
      } else {
        setSelected((previous) => previous.filter((fId) => fId !== feedId));
      }
    },
    [setSelected],
  );

  return (
    <div className={`${styles.feedSuggestionsTableContainer}`}>
      {orderedSuggestions.length ? (
        <table className={styles.suggestionsTable}>
          <Table.Header
            onHeaderClick={onHeaderClick}
            headers={tableHeaders}
            currentSortKey={sortByKey}
            currentSortOder={sortOrder}
            coverBorder={false}
          />

          <tbody>
            {orderedSuggestions.map((feed) => {
              return (
                <FeedSuggestionItem
                  key={feed.id}
                  id={feed.id}
                  feed={feed}
                  isChecked={selectedFeeds.includes(feed.id)}
                  onCheck={handleFeedSelection}
                />
              );
            })}
          </tbody>
        </table>
      ) : (
        emptyTable
      )}
    </div>
  );
});

const RestoreFeedSuggestionsDialog = ({
  isOpen,
  suggestionsCount,
  onConfirm,
  onCancel,
}: { isOpen: boolean; suggestionsCount: number; onConfirm: () => void; onCancel: () => void }) => {
  if (isOpen) {
    return (
      <div>
        <Dialog
          id="restore-feed-suggestions"
          title="Restore deleted suggestions?"
          subtitle={`This will restore ${suggestionsCount} suggestions that you have previously deleted.`}
          actionTitle="Restore suggestions"
          cancelTitle="Cancel"
          action={onConfirm}
          cancelAction={onCancel}
        />
      </div>
    );
  }
  return <></>;
};

export const FeedsSuggestionList = React.memo(function FeedsSuggestionList() {
  const [restoreHighSignalDialogOpen, setRestoreHighSignalDialogOpen] = useState(false);
  const [restoreLowSignalDialogOpen, setRestoreLowSignalDialogOpen] = useState(false);

  const rssFeeds = useGlobalStateWithFallback(
    {},
    useCallback((state) => state.persistent.rssFeeds, []),
  );
  const deletedSuggestions = useGlobalStateWithFallback(
    {},
    useCallback((state) => state.persistent.deletedRssSuggestions, []),
  );

  const cachedFeedSuggestions = useGlobalStateWithFallback(
    [],
    useCallback((state) => state.temporarilyCachedFeedSuggestions, []),
  );

  const cachedFeedSuggestionsSelection = globalState(
    useCallback((state) => state.temporarilyCachedFeedSuggestionsSelection, []),
  );
  const cachedFeedSuggestionsSelectionRef = useLiveValueRef(cachedFeedSuggestionsSelection);
  const history = useHistory();
  const shortcutsMap = useShortcutsMap();

  const [highSignalDropdownOpen, setHighSignalDropdownOpen] = useState(false);
  const [lowSignalDropdownOpen, setLowSignalDropdownOpen] = useState(false);
  const [highSignalFeeds, setHighSignalFeeds] = useState<RSSSuggestion[]>([]);
  const [lowSignalFeeds, setLowSignalFeeds] = useState<RSSSuggestion[]>([]);
  const [selectedHighSignalFeeds, setSelectedHighSignalFeeds] = useState<string[]>(
    cachedFeedSuggestionsSelection?.high ?? [],
  );
  const [selectedLowSignalFeeds, setSelectedLowSignalFeeds] = useState<string[]>(
    cachedFeedSuggestionsSelection?.low ?? [],
  );
  const [feedSuggestionsFromAPI, setFeedSuggestionsFromAPI] =
    useState<RSSSuggestion[]>(cachedFeedSuggestions);
  const [isLoading, setIsLoading] = useState(!cachedFeedSuggestions.length);
  const [temporarilyHiddenFeeds, setTemporarilyHiddenFeeds] = useState<string[]>([]);

  const allSelectedFeeds = useMemo(
    () => [
      ...highSignalFeeds.filter((f) => selectedHighSignalFeeds.includes(f.id)),
      ...lowSignalFeeds.filter((f) => selectedLowSignalFeeds.includes(f.id)),
    ],
    [selectedHighSignalFeeds, selectedLowSignalFeeds, highSignalFeeds, lowSignalFeeds],
  );

  useEffect(() => {
    updateState(
      (state) => {
        state.temporarilyCachedFeedSuggestionsSelection = {
          low: selectedLowSignalFeeds,
          high: selectedHighSignalFeeds,
        };
      },
      {
        eventName: 'caching-feed-suggestions-selection-data',
        userInteraction: null,
      },
    );
  }, [selectedLowSignalFeeds, selectedHighSignalFeeds]);

  useEffect(() => {
    const tempFunc = async () => {
      try {
        const data = await background.getUserFeedSuggestions();
        setFeedSuggestionsFromAPI(data);
        updateState(
          (state) => {
            state.temporarilyCachedFeedSuggestions = data;
          },
          {
            eventName: 'caching-feed-suggestions-data',
            userInteraction: null,
          },
        );
      } catch (e) {
        logger.error('Failed to fetch feed suggestions', { e });
        setFeedSuggestionsFromAPI([]);
        setIsLoading(false);
      }
    };
    tempFunc();
  }, []);

  useEffect(() => {
    if (feedSuggestionsFromAPI.length || cachedFeedSuggestions.length) {
      const alreadySubscribedFeeds = Object.entries(rssFeeds).map(
        ([feedId, feedData]) => feedData.db_feed_id ?? feedId,
      );
      const alreadyDeletedSuggestions = [
        ...(deletedSuggestions.low ?? []),
        ...(deletedSuggestions.high ?? []),
      ];
      const highSignal: RSSSuggestion[] = [];
      const lowSignal: RSSSuggestion[] = [];
      for (const feed of feedSuggestionsFromAPI) {
        if (alreadySubscribedFeeds.includes(feed.id)) {
          continue;
        }
        if (alreadyDeletedSuggestions.includes(feed.id)) {
          continue;
        }
        if (feed.signal === 'low') {
          lowSignal.push(feed);
        } else {
          highSignal.push(feed);
        }
      }
      setHighSignalFeeds(highSignal);
      setLowSignalFeeds(lowSignal);
      if (
        !(
          (cachedFeedSuggestionsSelectionRef.current?.low ?? []).length +
          (cachedFeedSuggestionsSelectionRef.current?.high ?? []).length
        )
      ) {
        setSelectedHighSignalFeeds(highSignal.map((f) => f.id));
      }
    }

    setIsLoading(false);
    setTemporarilyHiddenFeeds([]);
  }, [
    feedSuggestionsFromAPI,
    deletedSuggestions,
    rssFeeds,
    cachedFeedSuggestions,
    cachedFeedSuggestionsSelectionRef,
  ]);

  useEffect(() => {
    setHighSignalFeeds((previous) => previous.filter((f) => !temporarilyHiddenFeeds.includes(f.id)));
    setLowSignalFeeds((previous) => previous.filter((f) => !temporarilyHiddenFeeds.includes(f.id)));
  }, [temporarilyHiddenFeeds]);

  const onDelete = useCallback(async () => {
    if (selectedHighSignalFeeds.length) {
      await deleteFeedSuggestions(selectedHighSignalFeeds, 'high', { userInteraction: 'click' });
      setSelectedHighSignalFeeds([]);
    }
    if (selectedLowSignalFeeds.length) {
      await deleteFeedSuggestions(selectedLowSignalFeeds, 'low', { userInteraction: 'click' });
      setSelectedLowSignalFeeds([]);
    }
  }, [selectedLowSignalFeeds, selectedHighSignalFeeds]);

  const onSubscribe = useCallback(async () => {
    setTemporarilyHiddenFeeds(allSelectedFeeds.map((f) => f.id));
    await subscribeToSuggestedFeeds(allSelectedFeeds, { userInteraction: 'click' });
  }, [allSelectedFeeds]);

  useKeyboardShortcutPreventDefault(
    shortcutsMap[ShortcutId.Tab],
    useCallback(() => history.push('/feed/sources'), [history]),
  );
  useKeyboardShortcutPreventDefault(
    shortcutsMap[ShortcutId.PreviousTab],
    useCallback(() => history.push('/feed/sources'), [history]),
  );

  return (
    <div className={styles.sources}>
      <div className={styles.sourcesContainer}>
        <div className={styles.header}>
          <div className={styles.leftNav}>
            <span className={styles.title}>Feeds</span>
            <ul className={styles.navPillsContainer}>
              <li>
                <Link to="/feed/sources" className={styles.navPill}>
                  Subscribed
                </Link>
              </li>
              <li>
                <Link to="/feed/suggestions" className={`${styles.navPill} ${styles.active}`}>
                  Suggested <span className={styles.newLabel}>New</span>
                </Link>
              </li>
            </ul>
          </div>
          <div className={styles.headerRight}>
            <div
              className={styles.suggestionsPrompt}
            >{`${allSelectedFeeds.length} suggestions selected:`}</div>
            <Button
              disabled={allSelectedFeeds.length < 1}
              variant="secondary"
              className={styles.deleteBtn}
              onClick={onDelete}
            >
              <DeleteIcon /> Delete
            </Button>
            <Button
              disabled={allSelectedFeeds.length < 1}
              variant="blue"
              className={styles.subscribeBtn}
              onClick={onSubscribe}
            >
              <FeedIconHeader /> Subscribe
            </Button>
          </div>
        </div>
        {isLoading ? (
          <div className={styles.loaderContainer}>
            <Loader />
            <p>Fetching feed suggestions</p>
          </div>
        ) : (
          <div className={`${styles.mainSuggestionsContent} has-visible-scrollbar`}>
            <div className={styles.feedSuggestionTableTitle}>
              <div>
                <p>High signal feeds</p>
                <span>Sources that you’ve frequently saved, read, and highlighted.</span>
              </div>
              <div>
                <Dropdown
                  options={[
                    {
                      type: DropdownOptionType.Item,
                      name: 'Restore deleted suggestions',
                      shortcut: undefined,
                      isDisabled: (deletedSuggestions.high ?? []).length < 1,
                      onSelect: () => setRestoreHighSignalDialogOpen(true),
                    },
                  ]}
                  isOpen={highSignalDropdownOpen}
                  contentClassName={styles.restoreDropdown}
                  side="bottom"
                  triggerClassName={styles.restoreBtn}
                  setIsOpen={setHighSignalDropdownOpen}
                  appendToDocumentBody
                />
              </div>
            </div>
            <FeedSuggestionsTable
              id="high-signal-table"
              feedsSuggestions={highSignalFeeds}
              setSelected={setSelectedHighSignalFeeds}
              selectedFeeds={selectedHighSignalFeeds}
              emptyTable={
                <TableEmptyState
                  icon={<RadarIcon variant="high" />}
                  text="High signal feeds will show up here"
                  className={styles.high}
                />
              }
            />
            <div className={styles.feedSuggestionTableTitle}>
              <div>
                <p>Lower signal feeds</p>
                <span>Sources that you’ve occasionally saved, read, and highlighted. </span>
              </div>
              <div>
                <Dropdown
                  options={[
                    {
                      type: DropdownOptionType.Item,
                      name: 'Restore deleted suggestions',
                      shortcut: undefined,
                      isDisabled: (deletedSuggestions.low ?? []).length < 1,
                      onSelect: () => setRestoreLowSignalDialogOpen(true),
                    },
                  ]}
                  isOpen={lowSignalDropdownOpen}
                  contentClassName={styles.restoreDropdown}
                  side="bottom"
                  triggerClassName={styles.restoreBtn}
                  setIsOpen={setLowSignalDropdownOpen}
                  appendToDocumentBody
                />
              </div>
            </div>
            <FeedSuggestionsTable
              id="low-signal-table"
              feedsSuggestions={lowSignalFeeds}
              setSelected={setSelectedLowSignalFeeds}
              selectedFeeds={selectedLowSignalFeeds}
              emptyTable={
                <TableEmptyState
                  icon={<RadarIcon variant="low" />}
                  text="Lower signal feeds will show up here"
                />
              }
            />
            <RestoreFeedSuggestionsDialog
              isOpen={restoreHighSignalDialogOpen}
              suggestionsCount={(deletedSuggestions.high ?? []).length}
              onConfirm={() => restoreDeletedFeedSuggestions('high', { userInteraction: 'click' })}
              onCancel={() => setRestoreHighSignalDialogOpen(false)}
            />
            <RestoreFeedSuggestionsDialog
              isOpen={restoreLowSignalDialogOpen}
              suggestionsCount={(deletedSuggestions.low ?? []).length}
              onConfirm={() => restoreDeletedFeedSuggestions('low', { userInteraction: 'click' })}
              onCancel={() => setRestoreLowSignalDialogOpen(false)}
            />
          </div>
        )}
      </div>
    </div>
  );
});
